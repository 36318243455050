/* eslint-disable no-undef */
import { $body } from "../utils/globals.js"
import { loadCoverVideo } from "../utils/load-cover-video.js";
import { controlSVPVideo } from '../utils/svp-video-controller.js';
import { loadSVPVideo } from '../utils/svp-video-controller.js';
import { memoScrollAnimation } from './responsive-page.js';

//Trigger menu on hamburger click
$('.js-trigger-menu').on("click", (e)=> {
	e.preventDefault();
	$body.toggleClass('is-menu-open')
})
//Trigger search on hamburger click
$('.js-trigger-search').on("click", (e)=> {
	e.preventDefault();
	$('.searchresults').removeClass('show');
})
//Close menu when clicked outside
$(document).mouseup(function (e) {
	var menu = $(".menu");

	if (!menu.is(e.target) && menu.has(e.target).length === 0) {
		$body.removeClass('is-menu-open');
	}
});

//Now Showing desktop functionality
if ($body.hasClass('page-season') || $body.hasClass('page-seasons')) {
	if (window.innerWidth > 767) {
		let prevClick
		$('.js-expand-tab').on("click", (e) => {
			// e.preventDefault();
			let $this = $(e.currentTarget);
			
			if (prevClick != $this.index()) {
				$this.addClass('is-active').siblings().removeClass('is-active');
				$this.find('.section__foot').css('visibility', 'visible')
				$this.find('.section__foot').addClass('show');

				loadCoverVideo($this);

				prevClick = $this.index()
			}

		});

		//If url has movie name
		if (!$(".section__body").find("li").first().hasClass("is-active")) {
			//Load movie previewvideo
			var movie = $(".section__body").find(".is-active")
			expandMovie(movie, false)
			loadCoverVideo(movie);
			prevClick = movie.index()
			if (movie.data('content')) {
				console.log(movie.data('content'))
				$('body').addClass('lock-scrolling');
				if (movie.data('content') == "memo") {
					memoScrollAnimation(movie)
				}
			}
		} else {
			//Load season cover (first video in list)
			loadCoverVideo($('li.js-expand-tab:first'));
		}
		//Expand movie tab on SeasonHeader
		function expandMovie(activeTab, updateUrl) {
			let trailerSvpId = activeTab.find('.movie-trailer-media > div').attr('id');
			let afterthoughtsSvpId = activeTab.find('.movie-afterthoughts-media > div').attr('id');
			if (trailerSvpId) {
				loadSVPVideo(trailerSvpId, 'trailer', false)
			}
			if (afterthoughtsSvpId) {
				loadSVPVideo(afterthoughtsSvpId, 'afterthoughts', false)
			}
			activeTab.siblings('li').addClass('minimise');
			activeTab.addClass('expand');
			activeTab.find('.section__foot').removeClass('show');
			activeTab.find('svg.btn--play').removeClass('show');
			if (updateUrl === true) {
				let movieId = activeTab.data("movieid")
				let movieUrl = activeTab.data("movieurl")
				console.log(movieUrl);
				history.pushState({ movieId: movieId, movieUrl: movieUrl }, null, movieUrl);
			}
			setTimeout(function () {
				activeTab.find('.section__foot').css('visibility', 'hidden');
				activeTab.find('svg.btn--play').css('visibility', 'hidden');
				activeTab.find('.movie__details').css('visibility', 'visible');
				activeTab.find('.movie__details').addClass('show');
			}, 400);
		}
		$('.js-expand-movie').on("click", (e) => {
			e.preventDefault();
			let $this = $(e.currentTarget);
			let activeTab = $this.parents('li');
			expandMovie(activeTab, true)
		})
		//Close movie expanded tab on SeasonHeader
		$('.js-close-movie').on("click", (e) => {
			e.preventDefault();
			let $this = $(e.currentTarget);
			let activeTab = $this.parents('li');
			activeTab.siblings('li').removeClass('minimise');
			activeTab.removeClass('expand');
			activeTab.find('.movie__details').css('transition-delay', '0');
			activeTab.find('.movie__details').removeClass('show');

			let seasonId = $('.section-gallery').find('ul li').first().data("seasonid")
			let seasonUrl = $('.section-gallery').find('ul li').first().data("seasonurl")
			history.pushState({ movieId: seasonId, movieUrl: seasonUrl }, null, seasonUrl);
			setTimeout(function () {
				activeTab.find('.movie__details').css('visibility', 'hidden');
				activeTab.find('.section__foot').css('visibility', 'visible');
				activeTab.find('.section__foot').addClass('show');
				activeTab.find('svg.btn--play').css('visibility', 'visible');
				activeTab.find('svg.btn--play').addClass('show');
			}, 500);
		})
		
	}
	//Open popup
	$('.js-open-popup').on("click", function (e) {
		e.preventDefault();
		var $this = $(e.currentTarget);
		var id = $this.attr('href');
		let movieUrl = $this.closest("li").data("movieurl")
		history.pushState({ movieId: $this.closest("li").data("movieid"), movieUrl: movieUrl }, null, movieUrl + "/" + id.split("-")[1]);
		$('custom-scroll').mCustomScrollbar("disable");
		$('li.is-active.expand').find(id).addClass('is-active');
		$('.popup__overlay').addClass('is-active');
		let movie = $this.closest("li")
		if (id == "#popup-memo") {
			memoScrollAnimation(movie)
		}
	});
	//Close popup and enable scrolling
	$('.popup .js-close-popup').on("click", (e) => {
		e.preventDefault();
		let $this = $(e.currentTarget);
		var id = $this.parents(".popup").attr("id")
		$this.parents('.popup').removeClass('is-active');
		$('.popup__overlay').removeClass('is-active');
		$('custom-scroll').mCustomScrollbar("update");
		let movieId = $this.closest("li").data("movieid")
		let movieUrl = $this.closest("li").data("movieurl")
		history.pushState({ movieId: movieId, movieUrl: movieUrl }, null, movieUrl);
		if (id == "popup-trailer" || id == "popup-afterthoughts") {
			controlSVPVideo($this.parents('.popup').find('.section__media div').attr('id'), 'pause')
		}
	})
}

//Stripe table redirect for users not logged in
$('.js-to-login').on("click", (e) => {
	e.preventDefault();
	window.location.href = '/login'+window.location.search
})
