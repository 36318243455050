export function isChromiumBrowser() {
	const userAgent = navigator.userAgent;
	const isChrome = /Chrome/.test(userAgent) && /Google Inc/.test(navigator.vendor);
	const isEdgeChromium = /Edg/.test(userAgent);
	const isOpera = /OPR/.test(userAgent);
	return isChrome || isEdgeChromium || isOpera;
}

export function isMobileDevice() {
	const userAgent = navigator.userAgent || navigator.vendor || window.opera;
	return /android|webos|iphone|ipod|blackberry|iemobile|opera mini/i.test(userAgent.toLowerCase()) &&
		!/ipad|tablet/i.test(userAgent.toLowerCase());
} 