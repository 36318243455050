import { changeLiveSlide } from './hero-slider.js';

var $ticker = $('.js-ticker');
var $tickerSlider = $ticker.find('.ticker__scroll');
var $tickerClose = $ticker.find('.ticker__btn');

$tickerClose.on("click", (e) => {
	let $this = $(e.currentTarget);
	$this.parents('.js-ticker').fadeOut()
	setTimeout(function() {
		$this.parents('.js-ticker').remove();
	}, 300);;
})


