import { gsap } from 'gsap';
import { ScrollTrigger } from "gsap/ScrollTrigger";

gsap.registerPlugin(ScrollTrigger);


$('.article__responsive').addClass('spinner');
$(window).on('load', function () {
    $('.article__responsive').removeClass('spinner');
    if (window.innerWidth > 767) {
        //Desktop animation layout

        if ($('body').hasClass("page-responsivePage") || $('body').hasClass("page-liveArticle")) {
            console.log("gsap running")
            gsap.utils.toArray(".slide").forEach(slide => {
                gsap.to(slide, {
                    autoAlpha: 1,
                    opacity: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: slide,
                        scroller: window,
                        start: "top 50%",
                        end: "bottom 50%",
                        markers: false,
                        toggleActions: "play reverse play reverse"
                    }
                })
            });
        }
    } else {
        $('.caption').each(function () {
            if ($(this).css('color') == 'rgb(255, 255, 255)' && $(this).parents('.keep__layout').length === 0) {
                $(this).css('color', '#000000');
            }
        });
    }
});

export function memoScrollAnimation(movie) {
    if (window.innerWidth > 767) {
        if ($('body').hasClass("page-season") || $('body').hasClass("page-seasons")) {
            var slides = movie.find(".slide")
            console.log("gsap init for", movie, slides)
            gsap.utils.toArray(".slide").forEach(element => {
                gsap.to(element, {
                    autoAlpha: 1,
                    opacity: 1,
                    ease: "none",
                    scrollTrigger: {
                        trigger: element,
                        scroller: ".article__memo",
                        start: "top 5%",
                        end: "bottom 5%",
                        markers: false,
                        toggleActions: "play reverse play reverse"
                    }
                })
            });
            setTimeout(() => {
                $('.slide').css('top', '0')
            }, 1000);
        }
    }
}
$('.magazine a, .article__responsive a').on('click', function (e) {
    e.preventDefault(); // Prevent the default navigation behavior
    var targetUrl = $(this).attr('href'); // Get the URL to navigate to
    console.log(targetUrl)
    // Fade out the body or a specific element, then navigate
    $('body').fadeOut(500, function () {
        window.location.href = targetUrl; // Redirect after fade out
    });
});
$('.page-responsivePage, .page-magazine, .page-issue, .page-magazineArchive').fadeIn(1600)
$('.article__responsive .collapsible').on('click', function (e) {
    e.preventDefault(); // Prevent the default navigation behavior
    $(this).toggleClass('active')
});