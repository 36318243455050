export function initSwipe(element) {
    element.addEventListener('touchstart', handleTouchStart);
    element.addEventListener('touchmove', handleTouchMove, { passive: false });
    element.addEventListener('touchend', handleTouchEnd);

    var xDown = null;
    var yDown = null;
    var touchMoved = false;

    function getTouches(event) {
        event.preventDefault();
        return event.touches ||             // browser API
            event.originalEvent.touches; // jQuery
    }

    function handleTouchStart(event) {
        event.preventDefault();
        event.preventDefault();
        const firstTouch = getTouches(event)[0];
        xDown = firstTouch.clientX;
        yDown = firstTouch.clientY;
        touchMoved = false;
    };

    let swipeRight = new CustomEvent('swipeRight');
    let swipeLeft = new CustomEvent('swipeLeft');
    let swipeDown = new CustomEvent('swipeDown');
    let swipeUp = new CustomEvent('swipeUp');
    let tap = new CustomEvent('tap');

    function handleTouchMove(event) {
        event.preventDefault();
        touchMoved = true;

        if (!xDown || !yDown) {
            return;
        }

        var xUp = event.touches[0].clientX;
        var yUp = event.touches[0].clientY;

        var xDiff = xDown - xUp;
        var yDiff = yDown - yUp;

        if (Math.abs(xDiff) > Math.abs(yDiff)) {/*most significant*/
            if (xDiff > 0) {
                /* right swipe */
                element.dispatchEvent(swipeRight);
            } else {
                /* left swipe */
                element.dispatchEvent(swipeLeft);
            }
        } else {
            if (yDiff > 0) {
                /* down swipe */
                element.dispatchEvent(swipeDown);
            } else {
                /* up swipe */
                element.dispatchEvent(swipeUp);
            }
        }
        /* reset values */
        resetValues();
    };

    function handleTouchEnd(event) {
        if (!touchMoved) {
            // If no touch move event was detected, dispatch the tap event
            element.dispatchEvent(tap);
        }
        resetValues();
    }
    function resetValues() {
        xDown = null;
        yDown = null;
    }
}

