import { controlSVPVideo } from '../utils/svp-video-controller.js';
import { loadSVPVideo } from '../utils/svp-video-controller.js';
import { isChromiumBrowser, isMobileDevice } from '../utils/browser-detect.js';
import { metaSet } from './meta.js';
import { initSwipe } from "../utils/mobile-gestures.js"
import { $window, $body } from "../utils/globals.js"


export function scrollToElement() {

}
//Change slide function
export function changeLiveSlide($currentSlide, changeUrl) {
	currentSlideId = $currentSlide.data("contentid")

	//Load SVP Video if exists
	currentSlideSVP = $currentSlide.find("div").attr('id');
	if (currentSlideSVP != null) {
		loadSVPVideo(currentSlideSVP, 'live', true)
	}

	//Scroll to slide and update lower third
	$currentInfo = $(`.hero__content-slide[data-contentid='${currentSlideId}']`);
	if (!isChromiumBrowser()) {
		$currentInfo[0].scrollIntoView({ behavior: 'auto', block: 'nearest', inline: 'start' });
		$currentSlide[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' });
	} else {
		let slideScroller = $('.hero .hero__media .hero__media-slides')[0]
		slideScroller.scrollTo({ left: $currentSlide[0].offsetLeft, behavior: 'smooth' })
		$currentInfo[0].scrollIntoView({ behavior: 'auto', block: 'nearest' });
	}

	//Update URL
	if (changeUrl == true) {
		//Update URL
		url = $currentSlide.data('url');
		history.pushState({ contentId: currentSlideId, contentUrl: url }, null, url);
		//Update SEO meta tags
		metaSet(currentSlideId)
	}

	

	//Update channel indicator
	var currChannelId = $currentSlide.data('channelid');
	var $channel = $(`.js-hero-slider .hero__content-actions ul li a[data-channelid=${currChannelId}]`);
	var channelName = $channel.data('channelname');
	$('.js-hero-slider .hero__content-actions ul li').removeClass('is-active');
	$channel.parent().addClass('is-active');
	$('#active-channel-name').val(channelName);
	$('.js-slide-channel-name-hover').text(channelName);
}

//Toggle read more function
export function readMoreToggle($target, forceClose) {
	if (forceClose == true && !$target.siblings('.hero__description').hasClass('shortened')) {
		$target.html('Read more');
		$target.siblings('.hero__description').addClass('shortened')
	} else if (forceClose == false) {
		$target.siblings('.hero__description').hasClass('shortened') ? $target.html('Close') : $target.html('Read more')
		$target.siblings('.hero__description').toggleClass('shortened')
	}
}

let $hero = $('.hero')
if ($body.hasClass('page-home') && !!$hero.length) {

	var $currentSlide, currentSlideId, currentSlideSVP, $currentInfo, url

	//Setup first slide
	$currentSlide = $('.hero__media-slide[data-startatme="1"]')
	$currentSlide.addClass("is-active")
	changeLiveSlide($currentSlide, false)

	//when  area is clicked, scrollTo the next / previous slide

	if (!isMobileDevice()) {
		$(".js-hero-slider .js-change-slide").on("click", function () {
			var $this = $(this)
			$currentSlide.removeClass("is-active")

			if (currentSlideSVP != null) {
				controlSVPVideo(currentSlideSVP, 'pause')
			}
			readMoreToggle($currentInfo.find('.js-read-more'), true)
			//If on last slide, go to first when next clicked
			if ($this.hasClass('prev')) {
				if ($currentSlide.index() != 0) {
					$currentSlide = $currentSlide.prev()
				} else {
					$currentSlide = $currentSlide.siblings(':last')
				}
			}
			else if ($this.hasClass('next')) {
				if ($currentSlide.index() != $('.hero__media-slide').length - 1) {
					$currentSlide = $currentSlide.next()
				} else {
					$currentSlide = $currentSlide.siblings(':first')
				}
			}
			$currentSlide.addClass("is-active")
			changeLiveSlide($currentSlide, true)
		});
	} else {
		$body.addClass('lock-scrolling')
		var swipeElement = document.createElement('div')
		swipeElement.classList.add('swipeable-area')
		$('.hero__media').append(swipeElement)
		initSwipe(swipeElement);
		swipeElement.addEventListener('swipeUp', function (e) {
			$currentSlide.removeClass("is-active")
			if (currentSlideSVP != null) {
				controlSVPVideo(currentSlideSVP, 'pause')
			}
			readMoreToggle($currentInfo.find('.js-read-more'), true)
			if ($currentSlide.index() != 0) {
				$currentSlide = $currentSlide.prev()
			} else {
				$currentSlide = $currentSlide.siblings(':last')
			}
			$currentSlide.addClass("is-active")
			changeLiveSlide($currentSlide, true)
		})
		swipeElement.addEventListener('swipeDown', function (e) {
			$currentSlide.removeClass("is-active")
			if (currentSlideSVP != null) {
				controlSVPVideo(currentSlideSVP, 'pause')
			}
			readMoreToggle($currentInfo.find('.js-read-more'), true)
			if ($currentSlide.index() != $('.hero__media-slide').length - 1) {
				$currentSlide = $currentSlide.next()
			} else {
				$currentSlide = $currentSlide.siblings(':first')
			}
			$currentSlide.addClass("is-active")
			changeLiveSlide($currentSlide, true)
		})

		swipeElement.addEventListener('tap', function (e) {
			if (currentSlideSVP != null) {
				controlSVPVideo(currentSlideSVP, 'toggle-play-pause')
			} else if ($currentSlide.find('a').attr('href').length != 0) {
				window.location.href = $currentSlide.find('a').attr('href')
			}
		})
		
	}
	
	$('.hero__media-slide').find('div').ready(function () {
		//Load first video of each channel
		$('.hero__content-actions ul li').each((index, channel) => {
			var channelId = $(channel).find('a').data('channelid')
			var firstOfChannel = $(`.hero__media-slide[data-channelid=${channelId}]`)
				.first().find("div").attr('id');
			if (firstOfChannel != null) {
				loadSVPVideo(firstOfChannel, 'live', false)
			}
		})
	})

	//Change channel
	$('.js-hero-slider .hero__content-actions ul li a').on("click", function (e) {
		e.preventDefault();
		let $this = $(e.currentTarget);
		let channelName = $this.data("channelname");
		$('.js-slide-channel-name-hover').text(channelName);
		$('.js-hero-slider .hero__content-actions ul li').removeClass('is-active');
		$(this).parent().addClass('is-active');
		$('#active-channel-name').val(channelName);

		let channelId = $(this).data('channelid');
		$currentSlide = $(`.js-hero-slider .hero__media-slides .hero__media-slide[data-channelid=${channelId}]`)
			.first();
		changeLiveSlide($currentSlide, true)
	});

	//Update channel name indicator on hover, return to active channel name on mouse leave
	$('.js-hero-slider .hero__content-actions ul a').hover(function (e) {
		e.preventDefault();
		let $this = $(e.currentTarget);
		let channelName = e.currentTarget.dataset.channelname;
		$('.js-slide-hover').html($this.html());
		$('.js-slide-channel-name-hover').text(channelName);
	}, function (e) {
		e.preventDefault();
		let $this = $(e.currentTarget);
		let activeChannelName = $('input#active-channel-name').val();
		$('.js-slide-channel-name-hover').text(activeChannelName);
	});

	$('.js-hero-guide').hover(function (e) {
		$hero.addClass('is-guide')
	}, function (e) {
		$hero.removeClass('is-guide')
	});

	//Toggle read more on channels
	$('.js-read-more').on("click", (e) => {
		e.preventDefault();
		let $this = $(e.currentTarget);
		readMoreToggle($this, false)
		
	})
}
