export function loadCoverVideo($target) {
    $('.section__cell .previewvideo').attr('src', '').removeClass('show');
    var $previewVideo = $target.find('.previewvideo');
    if ($previewVideo.length > 0) {
        console.log($previewVideo[0])
        $previewVideo.attr('src', $previewVideo.data('original-src'));
        $previewVideo.attr('autoplay', true);
        $previewVideo.attr('loop', true);
        $previewVideo.addClass('show');
    }
}