export function fetchMetaFields(contentId) {
    return fetch(`/SEO/GetMetaFields?contentId=${contentId}`)
        .then(response => {
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            return data;
        })
        .catch(error => {
            console.error("Error fetching meta fields:", error);
        });
}

var oldtitle = '';
var olddesc = '';
var oldimage = '';

export function metaSet(contentId) {
    fetchMetaFields(contentId).then(data => {
        $("title").html(`${data.title} &mdash; TANKtv` )
        
        $("meta[name='title']").attr("content", data.title);
        $("meta[property='og\\:title']").attr("content", data.title);
        $("meta[property='twitter\\:title']").attr("content", data.title);
        if (data.openGraphTitle && data.openGraphTitle != data.title) {
            $("meta[property='og\\:title']").attr("content", data.openGraphTitle);
            $("meta[property='twitter\\:title']").attr("content", data.title);
        }

        $("meta[name='description']").attr("content", data.metaDescription);
        $("meta[property='og\\:description']").attr("content", data.metaDescription);
        if (data.openGraphDescription && data.openGraphDescription != data.metaDescription) {
            $("meta[property='og\\:description']").attr("content", data.openGraphTitle);
            $("meta[property='twitter\\:description']").attr("content", data.metaDescription);
        }

        if (data.openGraphImage) {
            $("meta[property='og\\:image']").attr("content", data.openGraphImage);
            $("meta[property='twitter\\:image']").attr("content", data.openGraphImage);
        }

        $("link[rel='canonical']").attr("href", data.canonicalUrl);
        $("meta[property='og\\:url']").attr("content", data.canonicalUrl);
        if (data.openGraphUrl && data.openGraphUrl != data.canonicalUrl) {
            $("meta[property='og\\:url']").attr("content", data.openGraphTitle);
        }

        // Implement keywords selected by AI from the description or content
        //$("meta[name='keywords']").attr("content", data.keywords);
    });
}