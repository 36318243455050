/* eslint-disable no-undef */
import { $body } from "../utils/globals.js";
import { initSwipe } from "../utils/mobile-gestures.js";
import { loadCoverVideo } from "../utils/load-cover-video.js";
import { loadSVPVideo } from '../utils/svp-video-controller.js';
import { controlSVPVideo } from '../utils/svp-video-controller.js';

function changeMovie(currMovie, targetMovie, changeUrl) {
    if (currMovie) {
        currMovie.classList.remove('is-active', 'expand');
    }
    targetMovie.classList.add('is-active', 'expand');
    loadCoverVideo($(targetMovie));

    let trailerSvpId = $(targetMovie).find('.movie-trailer-media > div').attr('id');
    let afterthoughtsSvpId = $(targetMovie).find('.movie-afterthoughts-media > div').attr('id');
    if (trailerSvpId != null) {
        try {
            loadSVPVideo(trailerSvpId, 'trailer', false)
        } catch (error) {
            console.error("Error loading video:", error);
            console.log('trailerSvpId', trailerSvpId)
        }
    }
    if (afterthoughtsSvpId != null) {
        try {
            loadSVPVideo(afterthoughtsSvpId, 'afterthoughts', false)
        } catch (error) {
            console.error("Error loading video:", error);
            console.log('afterthoughtsSvpId', afterthoughtsSvpId)
        }
    }
    //Change trailer popup to full screen
    $('li').find("#popup-trailer").find('.section__media > div').css({
        width: '100vw',
        height: '100dvh'
    })
    $('li').find("#popup-trailer").find('.section__media > div > div').css({
        width: '100vw',
        height: '100dvh'
    })

    $(targetMovie).find('.section__foot').removeClass('show');

    targetMovie.scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })

    if (changeUrl) {
        let movieId = $(targetMovie).closest("li").data("movieid")
        let movieUrl = $(targetMovie).closest("li").data("movieurl")
        history.pushState({ movieId: movieId, movieUrl: movieUrl }, null, movieUrl);
    }
}

if ($body.hasClass('page-season')) {
    if (window.innerWidth < 767) {
        //Init swipe gesture event listeners
        $body.addClass('lock-scrolling')
        var gallery = $('.section-gallery')[0]
        initSwipe(document)
        //Show all movie__details
        $('.movie__details').addClass('show');
        $('.movie__details').css('visibility', 'visible');
        $('.js-close-movie').css('display', 'none');
        //If movie url
        console.log($(".section__body").find("li").first()[0])
        if (!$(".section__body").find("li").first().hasClass("is-active")) {
            console.log('first movie isnt active')
            var movie = $(".section__body").find(".is-active")[0]
            changeMovie(null, movie, false)
        }
        

        document.addEventListener('swipeDown', function (e) {
            var currMovie = $('li.is-active')[0]
            var nextMovie = $(currMovie).next('li')[0];
            if (nextMovie != null) {
                changeMovie(currMovie, nextMovie, true)
            } else {
                console.log('Scroll to seasons')
                $('section.section-movie')[0].scrollIntoView({ behavior: 'smooth', block: 'nearest', inline: 'start' })
                $('section.section-movie').css('overflow','scroll !important')
            }
            // console.log('Swiped down on movie', currMovie, nextMovie)
        })
        document.addEventListener('swipeUp', function (e) {
            var currMovie = $('li.is-active')[0];
            var prevMovie = $(currMovie).prev('li')[0];

            if (prevMovie != null) {
                changeMovie(currMovie, prevMovie, true)
            }
            // console.log('Swiped up on movie', currMovie, prevMovie)
        })
        //Open popup mobile
    }
}

