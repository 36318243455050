import {
	$window,
	$body
} from "../utils/globals.js"


var timeOutMove;


let resetTimeOut = () => {
	clearTimeout(timeOutMove);
	$body.removeClass('no-movement')
	timeOutMove = setTimeout(function(){
		$body.addClass('no-movement')
	}, 4000);
}

resetTimeOut();

$window.on('mousemove touchstart click', function(event){
	resetTimeOut();
})
