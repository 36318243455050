import $ from 'jquery';
import 'jquery-mousewheel';

import '../scss/style.scss';

import "./modules/audio-player.js";
import "./modules/click-events.js";
import "./modules/custom-scrollbar.js";
import "./modules/hero-slider.js";
import "./modules/move-detect.js";
import "./modules/now-showing-mobile.js";
import "./modules/responsive-page.js";
import "./modules/now-showing-promo.js";
import "./modules/search.js";
import "./modules/ticker.js";

import { initSwipe } from "./utils/mobile-gestures.js"
import { loadSVPVideo } from './utils/svp-video-controller.js';
import { controlSVPVideo } from './utils/svp-video-controller.js';
import { $window, windowHeight } from "./utils/globals.js";

let root = document.documentElement;

//Add menu shadow if background is white
if ($('body').css('background-color') === 'rgb(255, 255, 255)') {
	$('.menu').addClass('shadow')
}

$('.js-play-inline').each((index, video) => {
	video.play();
})

setTimeout(function() {
	$('.popup--login').addClass('is-active');
}, 120000);

root.style.setProperty('--window-height', windowHeight + "px");
$window.on('load resize scroll', () =>{
	root.style.setProperty('--window-height', windowHeight + "px");
})

//Pre-movie curtain video
if ($('body').hasClass('page-movie')) {
    $(function () {
        var checkCurtains = setTimeout(function () {
            if ($(".curtains").length > 0) {
                var curtains = videojs("curtains");
                var doTransition = setTimeout(function () {
                    $(".curtains").addClass("hide");
                }, 12000);
                curtains.on("timeupdate", function () {
                    if (!curtains.paused()) {
                        if (curtains.currentTime() > 5) {
                            curtains.pause();
                            var $prerolls = $(".preroll");
                            var i = 0;
                            if ($prerolls.length > 0) {
                                playPreroll($prerolls.eq(i));
                            } else {
                                playMovie();
                            }
                        }

                        function playPreroll($el) {
                            var $vidContainer = $el;
                            var preroll = videojs($el[0], {
                                autoplay: "any",
                                controlBar: {
                                    playToggle: true,
                                    fullscreenToggle: false,
                                    currentTimeDisplay: false,
                                    timeDivider: false,
                                    durationDisplay: false,
                                    remainingTimeDisplay: false,
                                    progressControl: false,
                                    volumeControl: true,
                                    muteToggle: false,
                                    pictureInPictureToggle: false,
                                    volumePanel: true,
                                },
                            });
                            preroll.ready(function () {
                                preroll.addClass("show");
                                preroll.play().catch((err) => console.log(err));
                                preroll.removeClass("playing");
                                preroll.on("ended", function () {
                                    preroll.removeClass("show");
                                    preroll.addClass("hide");
                                    preroll.removeClass("playing");
                                    preroll.addClass("played");

                                    i++;
                                    if (i < $prerolls.length) {
                                        playPreroll($prerolls.eq(i));
                                    } else {
                                        playMovie();
                                    }
                                });
                            });
                        }
                    }
                });
                clearTimeout(checkCurtains);
            }
        }, 100);

        function playMovie() {
            console.log("main movie begin");
            $(".main-movie").addClass("show");
            var svpid = $(".main-movie div").attr("id");
            loadSVPVideo(svpid, 'movie', true)
            var svp_player = SvpPlayerInstances.getPlayerInstance(svpid);
            svp_player.play();
        }

        $(".curtains, .preroll, body").click(function () {
            $("video").each(function (i, val) {
                $(val).removeAttr("muted");
            });

            videojs($(".preroll.playing")[0]).play();
        });
    });
}
