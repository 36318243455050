export function controlSVPVideo(svpId, command) {
	var Id = svpId.replace('svp_player', '');
	var video = SvpPlayerInstances.getPlayerInstance(Id);
	//console.log(video)
	if (command === 'play') {
		video.play();
		// console.log('Playing video', video);
	} else if (command === 'play-muted') {
		video.mute();
		video.play();
		// console.log('Playing video muted', video);
	} else if (command === 'pause') {
		video.pause();
		// console.log('Pausing video', video);
	} else if (command === 'stop') {
		video.stop();
		// console.log('Stopping video', video);
	} else if (command === 'toggle-play-pause') {
		if (video.isPlaying()) { video.pause(); }
		else if (video.isPaused()) { video.play(); }
	}
}

export function loadSVPVideo(svpId, player, playOnLoad) {
	//console.log('Loading video', svpId)
	var Id = svpId.replace('svp_player', '');
	var vars, svp_player
	if (player === 'live') {
		vars = { clip_id: Id, transparent: "true", pause: "1", repeat: "", bg_color: "#ffffff", fs_mode: "2", no_controls: "", start_img: "0", start_volume: "50", close_button: "", brand_new_window: "1", auto_hide: "1", stretch_video: "1", player_align: "NONE", offset_x: "0", offset_y: "0", player_color_ratio: 0.6, skinAlpha: "50", colorBase: "#020008", colorIcon: "#ffffff", colorHighlight: "#010002", direct: "false", is_responsive: "true", viewers_limit: 0, cc_position: "bottom", cc_positionOffset: 70, cc_multiplier: 0.03, cc_textColor: "#ffffff", cc_textOutlineColor: "#ffffff", cc_bkgColor: "#000000", cc_bkgAlpha: 0.1, aspect_ratio: "adaptive", play_button: "1", play_button_style: "pulsing", sleek_player: "1", auto_play: "0", auto_play_type: "unMute", floating_player: "none" };
		svp_player = new SVPDynamicPlayer(`svp_player${Id}`, "", "100%", "100%", { use_div: `svp_player${Id}`, skin: "3" }, vars);
	} else if (player === 'movie') {
		vars = { clip_id: Id,transparent:"true",pause:"0",repeat:"",bg_color:"#ffffff",fs_mode:"2",no_controls:"",start_img:"0",start_volume:"100",close_button:"",brand_new_window:"1",auto_hide:"1",stretch_video:"",player_align:"NONE",offset_x:"0",offset_y:"0",player_color_ratio:0.6,skinAlpha:"50",colorBase:"#000000",colorIcon:"#FEE600",colorHighlight:"#000000",direct:"false",is_responsive:"true",viewers_limit:0,cc_position:"bottom",cc_positionOffset:70,cc_multiplier:0.03,cc_textColor:"#ffffff",cc_textOutlineColor:"#ffffff",cc_bkgColor:"#000000",cc_bkgAlpha:0.1,aspect_ratio:"adaptive",play_button:"1",play_button_style:"static",sleek_player:"0",auto_play:"1",auto_play_type:"mute",floating_player:"none"};
		svp_player = new SVPDynamicPlayer(`svp_player${Id}`, "", "100%", "100%", { use_div: `svp_player${Id}`,skin:"3"}, vars);
	} else if (player === 'trailer') {
		vars = { clip_id: Id, transparent: "true", pause: "1", repeat: "", bg_color: "#ffffff", fs_mode: "2", no_controls: "", start_img: "0", start_volume: "100", close_button: "", brand_new_window: "1", auto_hide: "1", stretch_video: "", player_align: "NONE", offset_x: "0", offset_y: "0", player_color_ratio: 0.6, skinAlpha: "80", colorBase: "#000000", colorIcon: "#fee600", colorHighlight: "#000000", direct: "false", is_responsive: "false", viewers_limit: 0, cc_position: "bottom", cc_positionOffset: 70, cc_multiplier: 0.03, cc_textColor: "#ffffff", cc_textOutlineColor: "#ffffff", cc_bkgColor: "#000000", cc_bkgAlpha: 0.1, aspect_ratio: "adaptive", play_button: "1", play_button_style: "static", sleek_player: "0", auto_play: "0", auto_play_type: "unMute", floating_player: "none" };
		svp_player = new SVPDynamicPlayer(`svp_player${Id}`, "", "720", "405", { use_div: `svp_player${Id}`, skin: "3" }, vars);
	} else if (player === 'afterthoughts') {
		vars = { clip_id: Id, transparent: "true", pause: "1", repeat: "", bg_color: "#ffffff", fs_mode: "2", no_controls: "", start_img: "0", start_volume: "100", close_button: "", brand_new_window: "1", auto_hide: "1", stretch_video: "", player_align: "NONE", offset_x: "0", offset_y: "0", player_color_ratio: 0.6, skinAlpha: "80", colorBase: "#000000", colorIcon: "#fee600", colorHighlight: "#000000", direct: "false", is_responsive: "true", viewers_limit: 0, cc_position: "bottom", cc_positionOffset: 70, cc_multiplier: 0.03, cc_textColor: "#ffffff", cc_textOutlineColor: "#ffffff", cc_bkgColor: "#000000", cc_bkgAlpha: 0.1, aspect_ratio: "16:9", play_button: "1", play_button_style: "static", sleek_player: "0", auto_play: "0", auto_play_type: "unMute", floating_player: "none" };
		svp_player = new SVPDynamicPlayer(`svp_player${Id}`, "", "100%", "100%", { use_div: `svp_player${Id}`, skin: "3" }, vars);
	}
	svp_player.execute();

	if (playOnLoad) {
		svp_player.addPlayerEventListener('PLAYER.STARTED', function (event) {
			controlSVPVideo(svpId, 'play-muted');
		});
	}
}