import mCustomScrollbar from "malihu-custom-scrollbar-plugin";

//Add custom scrollbar to basic pages with overflow
if ($('.article__basic').height() > $(window).height()) {
	$('.article__basic').parent('.wrapper').addClass('custom-scroll');
	console.log('height is bigger', $('.article__basic').parent('.wrapper'))
};
// Add custom scrollbars
$('.custom-scroll').mCustomScrollbar();
//Scroll custom scrollbar if link has #
setTimeout(function () {
	if (document.location.href.includes("#")) {
		let elID = document.location.href.split("#")[1];
		let el = $("section#" + elID)[0];
		$(".custom-scroll").mCustomScrollbar("scrollTo", el);
	}
}, 1000);

//Podcast page: Scroll to element if context has episode name
$(".article-podcast.is-active").parents(".custom-scroll").mCustomScrollbar("scrollTo", ".article-podcast.is-active")
