initSearch();
function modifyQS(url, property, value) {
	var newUrl = new URL(url);
	var params = new URLSearchParams(newUrl.search);

	if (value === '') {
		params.delete(property);
	} else {
		params.set(property, value);
	}

	newUrl.search = params.toString();
	history.replaceState({ origUrl: url }, "EthosEnergy", unescape(newUrl));
};

$(document).on('keyup', 'body', function (e) {
	if (e.key === 'Escape') {
		$('body').removeClass('is-menu-open');
		$('.searchresults').removeClass('show');
	}
});

function initSearch() {
	console.log('Search Init...');

	$('.js-input-search, .js-input-search-under').val(localStorage.getItem('lastsearch'));

	$(document).on('keyup', '.js-input-search', function (e) {
		$('.js-input-search-under').val($('.js-input-search').val());
	});
	$(document).on('keyup', '.js-input-search-under', function (e) {
		$('.js-input-search').val($('.js-input-search-under').val());

		var val = $('.js-input-search').val();
		if (val.length >= 3) {
			Search(val);
			$('.js-input-search').focus();
			$('body').removeClass('is-menu-open');
			$('.searchresults').addClass('show');
		}
	});

	$(document).on('keyup', '.js-input-search', function (e) {
		clearTimeout(window.debounceSearch);
		window.debounceSearch = setTimeout(function () {
			var val = $(".js-input-search").val();

			modifyQS(window.location.href, "q", val);

			if (val.length >= 3) {
				Search(val);

				localStorage.setItem('lastsearch', val);
			}
			else if (val.length == 0) {
				$('.searchresults .list').html('');
				$('.searchresults').removeClass('show');
			}

			clearTimeout(window.debounceSearch);
		}, 250);
	});

	$(document).on('click', '.searchresults .list li', function (e) {
		window.location.href = $(this).data('href');
	});

	$('.search__btn').click(function (e) {
		Search($('.js-input-search').val());
	});

	function Search() {
		var val = $(".js-input-search-under").val();
		var url = `/umbraco/surface/SearchSurface/Search?q=${encodeURI(val)}`;
		//console.log('Searching...');

		$.ajax(url, {
			type: "POST",
			cache: false,
			contentType: false,
			processData: false,
			success: function (data, textStatus, jQxhr) {
				//console.log('Search Success!', `${data.length} results`);
				$('.searchresults .list').html('');
				$('.resultcount .num').html(data.length);

				for (var i = 0; i < data.length; i++) {
					var item = data[i];
					$('.searchresults').addClass('show');
					$('.searchresults .list').append(`<li data-href=${item.url}>
					<span>${item.title}</span>
					<span>
						<span>${item.location}</span>
						<br>
						<span>${item.dateString}</span>
					</span>
					</li>`);
				}
			},
			error: function (jqXhr, textStatus, errorThrown) {
				console.log('Search Error.');
				console.log(jqXhr, textStatus, errorThrown);
			},
			complete: function () {

			}
		});
	}
}